<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="
        showPreloader ||
        $store.state.refresh.fetchingNewData ||
        $store.state.refresh.waiting_filter
      "
      style="height: 90vh"
    >
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
      <table-car
        :dataTable="cars"
        @editForm="editForm"
        @refresh="refresh"
        @addPhotocontrol="addPhotocontrol"
      />
    </div>
    <modal-photocontrol :id="id"></modal-photocontrol>
    <modal-car @refresh="refresh" :fuel_types="fuel_types" />
    <modal-car-edit :id="id" @refresh="refresh" :fuel_types="fuel_types" />
    <modal-car-view
      :id="id"
      @refresh="refresh"
      :fuel_types="fuel_types"
    ></modal-car-view>
    <filter-modalVue
      :fields="fields"
      @sendToParent="sendToParent"
    ></filter-modalVue>
  </div>
</template>
<script>
import modalCarView from "@/views/component/Modal/ModalCar/modalCarView.vue";
import tableCar from "@/views/component/Table/tableCar.vue";
import modalCar from "@/views/component/Modal/ModalCar/modalCar.vue";
import modalCarEdit from "@/views/component/Modal/ModalCar/modalCarEdit.vue";
import modalPhotocontrol from "@/views/component/Modal/ModalPhotocontrol/modalPhotocontrol.vue";

export default {
  components: {
    tableCar,
    modalCar,
    modalCarEdit,
    modalPhotocontrol,
    modalCarView,
  },
  data() {
    return {
      id: "",
      showPreloader: false,
      fuel_types: [],
      cars: [],
      fields: [
        { key: "division", label: "Подразделение" },
        { key: "id", label: "Id" },
        { key: "model", label: "Модель" },
        { key: "category", label: "Категория" },
        { key: "condition", label: "Состояние" },
        { key: "year_of_issue", label: "Год выпуска" },
        { key: "car_number", label: "Номер машины" },
        { key: "fuel_type_name", label: "Тип топлива" },
        { key: "status", label: "Статус" },
        { key: "car_color", label: "Цвет" },
        { key: "body_type", label: "Тип кузова" },
        { key: "class", label: "Класс авто" },
        { key: "car_length", label: "Длина авто" },
        { key: "car_width", label: "Ширина авто" },
        { key: "car_heigth", label: "Высота авто" },
        { key: "count_seat", label: "Количество мест" },
      ],
    };
  },
  mounted() {
    this.fuelTypes();
    this.openFilter();
    this.$store.commit("pageData/setdataCount", null);
  },
  methods: {
    editForm(id) {
      this.id = id;
      setTimeout(this.openModal, 0);
    },
    sendToParent(tableData) {
      this.cars = tableData;
      this.$store.commit("pageData/setdataCount", this.cars.length);
    },
    addPhotocontrol(id) {
      this.id = id;
    },
    openModal() {
      this.$bvModal.show("modalCarEdit");
    },
    openFilter() {
      let allRoutes = this.$store.state.draggableTab.tabs;
      let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      let arrayRoutes = [];
      allRoutes.forEach((element) => {
        arrayRoutes.push(element.path);
      });
      if (arrayRoutes.includes(this.$route.path) == true) {
        this.showPreloader = true;
        this.cars = [];
        this.$http.get(`${this.$route.name}`, { params: param }).then((res) => {
          this.cars = res.data;
          this.$store.commit("pageData/setdataCount", this.cars.length);
          this.showPreloader = false;
          this.$store.commit("REFRESH_DATA", false);
        });
      } else if (arrayRoutes.includes(this.$route.path) == false) {
        this.$bvModal.show(this.$route.name + "filter");
      }
    },
    refresh() {
      this.showPreloader = true;
      this.$http.get("cars").then((res) => {
        this.cars = res.data;
        this.$store.commit("pageData/setdataCount", this.cars.length);
        this.showPreloader = false;
        this.$store.commit("REFRESH_DATA", false);
      });
    },
    fuelTypes() {
      this.$http.get("cars/fuel-types").then((res) => {
        this.fuel_types = res.data;
      });
    },
  },
  computed: {
    fetchingNewData() {
      return this.$store.state.refresh.fetchingNewData;
    },
  },
  watch: {
    fetchingNewData(val) {
      let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      if (val) {
        this.$http.get(`${this.$route.name}`, { params: param }).then((res) => {
          this.cars = res.data;
          this.$store.commit("pageData/setdataCount", this.cars.length);
          this.$store.commit("REFRESH_DATA", false);
        });
      }
    },
  },
};
</script>
